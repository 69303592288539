import React, {useEffect, useState} from "react";
import ReactSlider from "react-slick";
import {Slide} from "./slide";
import {SliderWrapper} from "./styledElements";

const mobilesettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const smallSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
};
const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
};
const isClient = typeof window !== 'undefined';

export const Slider = () => {
    const [sliderSettings, setSliderSettings] = useState(isClient ? window.innerWidth < 800
        ? mobilesettings
        : window.innerWidth < 1200 ? smallSettings : settings : settings);

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const handleResize = () => setSliderSettings(window.innerWidth < 800
            ? mobilesettings :
            window.innerWidth < 1200 ? smallSettings : settings);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    });

    return <SliderWrapper>
        <ReactSlider {...sliderSettings}>
            <Slide
                title="Les héros intranquilles de Bisky voltigent à Lausanne"
                magazine="24 Heures"
                date="7 December, 2020"
                link="https://www.24heures.ch/les-heros-intranquilles-de-bisky-voltigent-a-lausanne-498032646857"
            />
            <Slide
                title="Norbert Bisky: «La ville semble être en état de guerre»"
                magazine="Blue News"
                date="23 December, 2020"
                link="https://www.bluewin.ch/fr/lifestyle/norbert-bisky-la-ville-semble-etre-en-etat-de-guerre-498560.html"
            />
            <Slide
                title="From Lausanne to Miami: Exploring Real And Virtual Artworlds"
                magazine="Happy Ali"
                date="28 December, 2020"
                link="https://www.happy-ali.com/lifestyle/arts-culture/exploring-both-real-and-the-virtual-artworlds-from-lausanne-to-miami/"
            />
            <Slide
                title="Norbert Bisky: «Die Stadt fühlt sich an, als wäre sie im Kriegszustand»"
                magazine="Blue News"
                date="17 December, 2020"
                link="http://www.bluewin.ch/de/leben/lifestyle/norbert-bisky-die-stadt-fuehlt-sich-an-als-waere-sie-im-kriegszustand-493624.html"
            />
            <Slide
                title="NORBERT BISKY / Unrest"
                magazine="Xibt Magazine"
                date="30 November, 2020"
                link="https://www.xibtmagazine.com/en/2020/11/norbert-bisky-unrest/"
            />
        </ReactSlider>
    </SliderWrapper>
};
