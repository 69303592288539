import React, { useState, useRef, useEffect } from 'react';
import LazyLoadImage from './LazyLoadImage';

const absPos = { position: "absolute", top: 0, left: 0 };
const styles = {
  container: { position: "relative" },
  canvas: { ...absPos, pointerEvents: "none", zIndex: 2 },
  img: { ...absPos, zIndex: 1, userSelect: "none" },
  map: ({ cursor: "pointer" })
};

const ImageMapper = (
  {
    src,
    map,
    width,
    imageWidth,
    height,
    fillColor,
    strokeColor,
    onMouseEnter,
    onMouseLeave,
    lineWidth,
    onLoad,
    active,
      scrollPosition,
  }
) => {
  const canvasRef = useRef({});
  const imgRef = useRef({});
  const containerRef = useRef({});
  const [ctx, setCtx] = useState({});

  const scaleCoords = (coords) => {
    const scale = width && imageWidth && imageWidth > 0 ? width / imageWidth : 1;
    return coords.map(coord => coord * scale);
  };

  const computeCenter = (area) => {
    if (!area) return [0, 0];

    const scaledCoords = scaleCoords(area.coords);

    switch (area.shape) {
      case "circle":
        return [scaledCoords[0], scaledCoords[1]];
      case "poly":
      case "rect":
      default: {
        // Calculate centroid
        const n = scaledCoords.length / 2;
        const { y, x } = scaledCoords.reduce(
          ({ y, x }, val, idx) => {
            return !(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x };
          },
          { y: 0, x: 0 }
        );
        return [x, y];
      }
    }
  };

  const drawpoly = (coords, fillColor, lineWidth, strokeColor) => {
    coords = coords.reduce(
      (a, v, i, s) => (i % 2 ? a : [...a, s.slice(i, i + 2)]),
      []
    );

    ctx.fillStyle = fillColor;
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = strokeColor;
    let first = coords.unshift();
    ctx.moveTo(first[0], first[1]);
    coords.forEach(c => ctx.lineTo(c[0], c[1]));
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
    ctx.fillStyle = fillColor;
  };

  const drawShape = {
    poly: (coords, fillColor, lineWidth, strokeColor) => drawpoly(coords, fillColor, lineWidth, strokeColor)
  };

  const renderPrefilledAreas = () => {
    map.areas.map(area => {
      if (!area.preFillColor) return;
      return drawShape[area.shape](
        scaleCoords(area.coords),
        area.preFillColor,
        area.lineWidth || lineWidth,
        area.strokeColor || strokeColor,
      );
    });
  };

  const initCanvas = () => {
    if (width) imgRef.current.width = width;
    if (height) imgRef.current.height = height;

    canvasRef.current.width = width || imgRef.current.clientWidth;
    canvasRef.current.height = height || imgRef.current.clientHeight;

    containerRef.current.style.width = (width || imgRef.current.clientWidth) + "px";
    containerRef.current.style.height = (height || imgRef.current.clientHeight) + "px";
    const context = canvasRef.current.getContext("2d");
    context.fillStyle = fillColor;
    setCtx(context);
    if (onLoad) onLoad();
    renderPrefilledAreas(context);
  };

  useEffect(() => {
    initCanvas();
  }, [width]);

  const handleMouseEnter = (area, index, event) => {
    const shape = event.target.getAttribute("shape");

    if (active && drawShape[shape]) {
      drawShape[shape](
        event.target.getAttribute("coords").split(","),
        area.fillColor,
        area.lineWidth || lineWidth,
        area.strokeColor || strokeColor
      );
    }
    if (onMouseEnter) onMouseEnter(area, index, event);
  };

  const handleMouseLeave = (area, index, event) => {
    if (active) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      renderPrefilledAreas();
    }

    if (onMouseLeave) onMouseLeave(area, index, event);
  };

  const renderAreas = () => map.areas.map((area, index) => {
    const scaledCoords = scaleCoords(area.coords);
    const center = computeCenter(area);
    const extendedArea = { ...area, scaledCoords, center };
    return (
      <area
        key={area._id || index}
        shape={area.shape}
        coords={scaledCoords}
        onMouseEnter={(e) => handleMouseEnter(extendedArea, index, e)}
        onMouseLeave={(e) => handleMouseLeave(extendedArea, index, e)}
        href={area.href}
        alt=""
      />
    )
  });

  return (
    <div style={styles.container} ref={containerRef}>
        <LazyLoadImage
          width={width}
          effect="opacity"
          style={styles.img}
          src={src}
          useMap={`#${map.name}`}
          alt=""
          ref={imgRef}
          afterLoad={() => initCanvas()}
          scrollPosition={scrollPosition}
        />
        <canvas ref={canvasRef} style={styles.canvas} />
        <map name={map.name} style={styles.map}>
          {renderAreas()}
        </map>
    </div>
  )

};

ImageMapper.defaultProps = {
  active: true,
  fillColor: "rgba(255, 255, 255, 0.5)",
  lineWidth: 1,
  map: {
    areas: [],
    name: "image-map-" + Math.random()
  },
  strokeColor: "rgba(0, 0, 0, 0.5)"
};

export default ImageMapper;
