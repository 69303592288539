import React, { forwardRef, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyLoadImage = forwardRef((props, ref) => {
  const [loaded, setLoaded] = useState(false);

  const onImageLoad = () => {
    if (!loaded) {
      return () => {
        props.afterLoad();
        setLoaded(true);
      }
    }
  };

  const getImg = () => {
    const {
      afterLoad,
      beforeLoad,
      delayMethod,
      delayTime,
      effect,
      placeholder,
      placeholderSrc,
      scrollPosition,
      threshold,
      useIntersectionObserver,
      visibleByDefault,
      wrapperClassName,
      ...imgProps
    } = props;

    return <img onLoad={onImageLoad()} {...imgProps} ref={ref} alt="" />;
  };

  const getLazyLoadImage = () => {
    const {
      beforeLoad,
      className,
      delayMethod,
      delayTime,
      height,
      placeholder,
      scrollPosition,
      style,
      threshold,
      useIntersectionObserver,
      visibleByDefault,
      width,
    } = props;

    return (
      <LazyLoadComponent
        beforeLoad={beforeLoad}
        className={className}
        delayMethod={delayMethod}
        delayTime={delayTime}
        height={height}
        placeholder={placeholder}
        scrollPosition={scrollPosition}
        style={style}
        threshold={threshold}
        useIntersectionObserver={useIntersectionObserver}
        visibleByDefault={visibleByDefault}
        width={width}
      >
        {getImg()}
      </LazyLoadComponent>
    );
  }

  const getWrappedLazyLoadImage = (lazyLoadImage) => {
    const {
      effect,
      height,
      placeholderSrc,
      width,
      wrapperClassName,
    } = props;

    const loadedClassName = loaded ? ' lazy-load-image-loaded' : '';

    return (
      <span
        className={
          wrapperClassName +
          ' lazy-load-image-background ' +
          effect +
          loadedClassName
        }
        style={{
          backgroundImage:
            loaded || !placeholderSrc
              ? ''
              : `url(${placeholderSrc})`,
          backgroundSize:
            loaded || !placeholderSrc ? '' : '100% 100%',
          color: 'transparent',
          display: 'block',
          height: height,
          width: width,
        }}
      >
				{lazyLoadImage}
			</span>
    );
  };

  const { effect, placeholderSrc, visibleByDefault } = props;

  const lazyLoadImage = getLazyLoadImage();

  if ((!effect && !placeholderSrc) || visibleByDefault) {
    return lazyLoadImage;
  }

  return getWrappedLazyLoadImage(lazyLoadImage);

});

LazyLoadImage.defaultProps = {
  afterLoad: () => ({}),
  beforeLoad: () => ({}),
  delayMethod: 'throttle',
  delayTime: 300,
  effect: '',
  placeholderSrc: null,
  threshold: 100,
  useIntersectionObserver: true,
  visibleByDefault: false,
  wrapperClassName: '',
};

export default LazyLoadImage;

