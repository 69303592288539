import React, { useState } from 'react';
import ImageMapper from "./ImageMapper";
import { ImageWrapper } from "./styledElements";
import Tooltip from "./Tooltip";

const slugify = require('slug');

const TOOLTIP_MAX_WIDTH = 350;
const BASE_OFFSET_X = 20;
const BASE_OFFSET_Y = 40;
const BASE_MAPPER_WIDTH = 1500;

const ImageMap = ({
    id,
    imageFile,
    cdnPath,
    mapperWidth,
    artworks,
    visualisationArtworks,
    fillColor,
    strokeColor,
    language,
    slug,
    artistSlug,
    scrollPosition,
    hasMarginTop,
  }) => {
  const [hoveredArea, setHoveredArea] = useState(null);
  const [imgWidth, setImgWidth] = useState(BASE_MAPPER_WIDTH);

  const src = `${cdnPath}/walls/${imageFile}`;

  if (typeof Image !== 'undefined') {
    const img = new Image();
    img.src = src;
    img.onload = (e) => {
      setImgWidth(e.target.naturalWidth)
    };
  }

  const getArtworkById = (id) => artworks.find(a => a.id === id);

  // const getHref = (artworkId) => {
  //   const artwork = getArtworkById(artworkId);
  //   const { external_link } = artwork;
  //
  //   if (external_link && external_link !== 'x') {
  //     return external_link;
  //   }
  //
  //   return `/${language}/${slugify(
  //       artwork[slug],
  //       { lower: true },
  //   )}`;
  // };

    const getHref = (artworkId) => {
        const artwork = getArtworkById(artworkId);

        return `/${slugify(
            artistSlug ? artwork.artist + ' ' + artistSlug : artwork.artist,
            { lower: true }
        )}/${slugify(
            artwork[slug],
            { lower: true },
        )}`;
    };

  const getMap = () => ({
    name: `${id}`,
    areas: visualisationArtworks.map(v => ({
      name: v.artwork_id,
      shape: "poly",
      coords: v.coordinates.split(',').map(c => +c),
      href: getHref(v.artwork_id),
    }))
  });

  const getTooltipPosition = () => {
    const { center } = hoveredArea;
    const offsetRatio = mapperWidth / BASE_MAPPER_WIDTH;
    const offsetX = BASE_OFFSET_X * offsetRatio;
    const offsetY = BASE_OFFSET_Y * offsetRatio;

    const position = {
      top: `${center[1] + offsetY}px`,
    };

    if (mapperWidth - (center[0] + TOOLTIP_MAX_WIDTH) < 0) {
      position.right = `${mapperWidth - center[0] + offsetX}px`
    } else {
      position.left = `${center[0] + offsetX}px`;
    }
    return position;
  };

  return (
      <ImageWrapper
          id={'vis-' + id}
          key={id}
          hasMarginTop={hasMarginTop}
      >
        <ImageMapper
            src={src}
            map={getMap()}
            width={mapperWidth || BASE_MAPPER_WIDTH}
            imageWidth={imgWidth}
            fillColor={fillColor}
            strokeColor={strokeColor}
            onMouseEnter={area => setHoveredArea(area)}
            onMouseLeave={() => setHoveredArea(null)}
            scrollPosition={scrollPosition}
        />
        {hoveredArea && (
            <Tooltip
                artwork={getArtworkById(hoveredArea.name)}
                styles={{
                  ...getTooltipPosition(),
                  maxWidth: `${TOOLTIP_MAX_WIDTH}px`,
                }}
            />
        )}
      </ImageWrapper>
  )
};

export default ImageMap;
