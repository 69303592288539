import {useState, useEffect } from 'react';

const getWidth = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    return Math.min(window.innerWidth,
      (document.documentElement.clientWidth || document.body.clientWidth));
  }
  return 0;
};

export const useCurrentWidth = () => {
  // save current window width in the state object
  const [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth())
    };

    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);

    }
  }, []);

  return width;
};