import React from 'react';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import {
    VisualizationsInfo,
    VisualizationsWrapper,
} from './styledElements';
import ImageMap from "./ImageMap";
import { useCurrentWidth } from '../../helpers/hooks/useCurrentWidth';
import {Slider} from "./slider";

const Visualizations = (
    {
        visualizations,
        assetsPath,
        cdnPath,
        fillColor,
        strokeColor,
        language,
        artworks,
        slug,
        scrollPosition,
        hasSlider,
        artistSlug,
        isActive,
    }) => {
    const width = useCurrentWidth();
    const mapperWidth = width > 1600 ? width - 420: width;

    return (
        <>
            {hasSlider ? <Slider /> : null}
            <VisualizationsWrapper>
                <VisualizationsInfo>
                    <p>For more information click on each work</p>
                </VisualizationsInfo>
                {visualizations.map((
                    {
                        id,
                        image_file_name,
                        visualizations_artworks,
                    }, i
                ) => (
                    <ImageMap
                        key={id}
                        id={id}
                        imageFile={image_file_name}
                        artworks={artworks}
                        visualisationArtworks={visualizations_artworks}
                        mapperWidth={mapperWidth}
                        assetsPath={assetsPath}
                        cdnPath={cdnPath}
                        fillColor={fillColor}
                        strokeColor={strokeColor}
                        language={language}
                        slug={slug}
                        artistSlug={artistSlug}
                        scrollPosition={scrollPosition}
                        hasMarginTop={isActive && i === 5}
                    />
                ))}
            </VisualizationsWrapper>
        </>)

};

export default trackWindowScroll(Visualizations);
