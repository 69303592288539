import React  from 'react';
import { VideoWrapper } from "./styledElements";

const Video = ({
  videoUrl,
}) => {

    return (
        <VideoWrapper>
            <iframe title="exhibition-video" width="560" height="315" src={videoUrl} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </VideoWrapper>
    )
};

export default Video;
