import React from "react";
import {SlideDate, SlideMagazine, SlideTitle, SlideWrapper} from "./styledElements";

export const Slide = ({magazine, title, date, link}) => {
    return <SlideWrapper>
        <SlideMagazine>{magazine}</SlideMagazine>
        <SlideTitle>{title}</SlideTitle>
        <SlideDate>{date}</SlideDate>
        <a href={link} target="_blank" rel="noopener noreferrer">READ FULL ARTICLE <img src="/assets/arrow.svg" alt="" /></a>
    </SlideWrapper>
};
