import React from 'react';
import {
  TooltipInfo,
  TooltipTitle,
  TooltipArtist,
  TooltipContainer
} from './styledElements';
import {sliceTitle} from "../../helpers/sliceTitle";


const Tooltip = ({
  artwork,
  styles,
}) => {
  return (
    <TooltipContainer style={styles}>
      <TooltipArtist>{artwork.artist}</TooltipArtist>
      <TooltipTitle>{sliceTitle(artwork.artwork_title)}</TooltipTitle>
      <TooltipInfo>{artwork.date_of_creation}</TooltipInfo>
      <TooltipInfo>{artwork.technique}</TooltipInfo>
      <TooltipInfo>{artwork.signature}</TooltipInfo>
      <TooltipInfo>{artwork.dimensions}</TooltipInfo>
    </TooltipContainer>
  )
};

export default Tooltip;
