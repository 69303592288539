import styled from 'styled-components';

const SliderWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0 120px;
  
  @media all and (max-width: 1300px) {
    margin: 0 40px;
  }
  
  @media all and (max-width: 800px) {
    padding: 0 0 60px;
  }
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 31px;
    height: 58px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-color: transparent;

    cursor: pointer;

    border: none;
    outline: none;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-size: 20px;
    line-height: 1;

    opacity: .75;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -31px;
    background-image: url("/assets/ico_left.svg");
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-prev
{
    right: -31px;
    left: auto;
}

.slick-next
{
    right: -31px;
    background-image: url("/assets/ico_right.svg");
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -31px;
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
    
    @media all and (max-width: 800px) {
      bottom: -20px
    }
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 15px;
    height: 15px;
    margin: 0 10px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 15px;
    height: 15px;

    cursor: pointer;

    color: transparent;
    background: transparent;
    border: 0;
    outline: none;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    position: absolute;
    top: 0;
    left: 0;

    width: 15px;
    height: 15px;

    content: '';
    text-align: center;

    background: #D8D8D8;
    border-radius: 50%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
  background: #000;
}
`;

export {
    SliderWrapper,
}
